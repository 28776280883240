import * as React from "react";

import { Checkbox as CheckboxComponent } from "mu-styles";
import { Checkbox, RegistrationState } from "./registrationReducer";
import { StyledRegistrationModalBody } from "./styles";

interface ModalBody extends RegistrationState {
  onCheckboxChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isConsentChecked: boolean;
}


export const ConfirmationModalBody: React.FC<ModalBody> = ({
  e_mail,
  tel_num,
  egn,
  family,
  sec_name,
  first_name,
  checkboxes,
  onCheckboxChange,
  isConsentChecked,
}) => (
  <StyledRegistrationModalBody>
    Email: <strong>{e_mail.value}</strong> <br />
    Име: <strong>{first_name.value}</strong> <br />
    Презиме: <strong>{sec_name.value}</strong> <br />
    Фамилия: <strong>{family.value}</strong> <br />
    ЕГН: <strong>{egn.value}</strong> <br />
    Моб. номер: <strong>{tel_num.value}</strong> <br />
    Пол: <strong>{checkboxes[Checkbox.Male] ? "Мъж" : "Жена"}</strong>
    <CheckboxComponent
      color="#FFF"
      text="Запознат съм с ръководството за работа с онлайн платформата."
      onChange={onCheckboxChange}
      checked={isConsentChecked}
    />
  </StyledRegistrationModalBody>
);
